import { type SanityAccountDetailsStrings } from '@data/sanity/queries/types/blocks'
import { getLinkPageUrl } from '@lib/routes'
import { type UserCustomer } from '@lib/user/types'

import ButtonLink from '@components/buttons/button-link'

interface AccountCustomerDetailsProps {
  accountDetailsStrings: SanityAccountDetailsStrings
  customer?: UserCustomer
}

const AccountCustomerDetails = ({
  accountDetailsStrings,
  customer,
}: AccountCustomerDetailsProps) => {
  if (!customer) {
    return null
  }

  const accountAddressPageUrl = getLinkPageUrl({
    pageType: 'accountAddressPage',
  })

  return (
    <div className="space-y-4">
      <h4>{accountDetailsStrings.accountAccountDetailsHeading}</h4>

      <p>
        {`${customer.firstName} ${customer.lastName}`}
        <br />

        {customer.phone}
        <br />

        {customer.email}
      </p>

      <ButtonLink href={accountAddressPageUrl} variant="link">
        {accountDetailsStrings.accountViewAddresses}
      </ButtonLink>
    </div>
  )
}

export default AccountCustomerDetails
