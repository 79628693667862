import { type SanityVideo } from '@data/sanity/queries/types/video'

import MuxVideo from '@components/video/mux-video'

interface VideoModuleProps extends SanityVideo {
  className?: string
}

const VideoModule = ({
  type,
  muxVideo,
  settings,
  className,
  aspectRatio,
  borderRadius,
}: VideoModuleProps) => {
  return (
    <section className={className}>
      {type === 'mux' && muxVideo && (
        <MuxVideo
          video={muxVideo}
          showControls={settings?.controls}
          autoplay={settings?.autoplay}
          loop={settings?.loop}
          muted={settings?.muted}
          borderRadius={borderRadius}
          customAspectRatio={aspectRatio}
        />
      )}
    </section>
  )
}

export default VideoModule
