import cx from 'classnames'
import { useContext } from 'react'

import { type SanityAccountDetailsStrings } from '@data/sanity/queries/types/blocks'
import { UserContext } from '@lib/user/context'

import AccountCompanyDetails from './account-company-details'
import AccountCustomerDetails from './account-customer-details'

interface AccountDetailsProps {
  accountDetailsStrings: SanityAccountDetailsStrings
  className?: string
}

const AccountDetails = ({
  accountDetailsStrings,
  className,
}: AccountDetailsProps) => {
  const { user } = useContext(UserContext)

  if (!user) {
    return null
  }

  return (
    <div className={cx('flex flex-wrap gap-x-24 gap-y-7', className)}>
      <AccountCustomerDetails
        customer={user.customer}
        accountDetailsStrings={accountDetailsStrings}
      />
      <AccountCompanyDetails
        company={user.company}
        accountDetailsStrings={accountDetailsStrings}
      />
    </div>
  )
}

export default AccountDetails
