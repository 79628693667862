import { useCallback, useContext } from 'react'

import { type MailingAddressInput } from '@data/shopify/admin/types'
import {
  type GetCustomerAddressesQuery,
  type MailingAddressFragmentFragment,
} from '@data/shopify/storefront/types'
import { type AddressFormValues } from '@lib/address'
import { LanguageContext } from '@lib/language-context'
import {
  ParseError,
  parseResultsOk,
  parseResultsUnknownError,
} from '@lib/request'
import { ShopContext } from '@lib/shop-context'
import { UserContext } from '@lib/user/context'
import { type UserAddress } from '@lib/user/types'
import { parseShopifyGlobalId } from './helpers'
import {
  createShopifyCustomerAddress,
  deleteShopifyCustomerAddress,
  setShopifyCustomerDefaultAddress,
  updateShopifyCustomerAddress,
} from './graphql/addresses'

/**
 * Gets address form values from Shopify mailing address.
 */
const getAddressFormValues = (
  address: MailingAddressFragmentFragment,
  defaultAddressId?: string
) => {
  const addressFormValues: AddressFormValues = {
    firstName: address.firstName ?? '',
    lastName: address.lastName ?? '',
    company: address.company ?? '',
    address1: address.address1 ?? '',
    address2: address.address2 ?? '',
    city: address.city ?? '',
    country: address.country ?? '',
    zip: address.zip ?? '',
    phone: address.phone ?? '',
    isDefault: address.id === defaultAddressId,
  }

  return addressFormValues
}

/**
 * Gets Shopify address input from user addresses.
 */
export const getShopifyMailingAddressInput = (userAddresses: UserAddress[]) => {
  if (!userAddresses) {
    return
  }

  const defaultUserAddress = userAddresses.find(
    (userAddress) => userAddress.isDefault
  )
  const userAddress = defaultUserAddress ?? userAddresses[0]

  const input: MailingAddressInput = {
    firstName: userAddress.values.firstName,
    lastName: userAddress.values.lastName,
    company: userAddress.values.company,
    address1: userAddress.values.address1,
    address2: userAddress.values.address2,
    city: userAddress.values.city,
    countryCode: userAddress.countryCode,
    zip: userAddress.values.zip,
  }

  return input
}

/**
 * Gets user addresses from Shopify customer with addresses.
 */
export const getUserAddresses = (
  shopifyCustomer: GetCustomerAddressesQuery['customer']
) => {
  const defaultAddressId = shopifyCustomer?.defaultAddress?.id
  const userAddresses = shopifyCustomer?.addresses?.edges
    ?.map((edge) => {
      const address = edge.node

      if (!address.countryCodeV2) {
        return
      }

      const userAddress: UserAddress = {
        id: address.id,
        globalId: parseShopifyGlobalId(address.id),
        isDefault: address.id === defaultAddressId,
        formatted: address.formatted,
        countryCode: address.countryCodeV2,
        values: getAddressFormValues(address, defaultAddressId),
      }

      return userAddress
    })
    ?.filter(Boolean)

  return userAddresses ?? []
}

/**
 * Gets new default address.
 */
const getNewDefaultAddress = (
  addresses: UserAddress[],
  isDefault: boolean,
  globalId?: number
) => {
  const otherAddresses = addresses.filter(
    (address) => address.globalId !== globalId
  )

  if (isDefault || otherAddresses.length === 0) {
    return addresses.find((address) => address.globalId === globalId)
  }

  return (
    otherAddresses.find((address) => address.isDefault) ?? otherAddresses[0]
  )
}

/**
 * Returns a method that creates a new user address.
 */
export const useCreateAddress = () => {
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)
  const { user, mutateUser } = useContext(UserContext)

  return useCallback(
    async (addressFormValues: AddressFormValues, token: string) => {
      const { isDefault, ...input } = addressFormValues

      try {
        if (!shopifyStorefrontGraphQlClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        const address = await createShopifyCustomerAddress(
          locale,
          shopifyStorefrontGraphQlClient,
          token,
          input
        )
        const addressCountryCode = address?.countryCodeV2

        if (addressCountryCode) {
          if (isDefault) {
            await setShopifyCustomerDefaultAddress(
              locale,
              shopifyStorefrontGraphQlClient,
              token,
              address.id
            )
          }

          // Mutate user with the new address list
          const addresses = user?.addresses ? [...user.addresses] : []
          const newAddressFormValues = getAddressFormValues(address)
          const newAddresses: UserAddress[] = [
            ...addresses.map((address) => ({
              ...address,
              isDefault: !isDefault && !!address.isDefault,
            })),
            {
              id: address.id,
              globalId: parseShopifyGlobalId(address.id),
              formatted: address.formatted,
              isDefault: !!isDefault,
              countryCode: addressCountryCode,
              values: newAddressFormValues,
            },
          ]
          mutateUser(
            {
              ...user,
              isLoggedIn: !!user?.isLoggedIn,
              addresses: newAddresses,
            },
            {
              revalidate: false,
            }
          )
        }

        return parseResultsOk
      } catch (error) {
        console.log(error)

        return error instanceof ParseError
          ? error.parseResults
          : parseResultsUnknownError
      }
    },
    [locale, mutateUser, shopifyStorefrontGraphQlClient, user]
  )
}

/**
 * Returns a method that updates a user address.
 */
export const useUpdateAddress = () => {
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)
  const { user, mutateUser } = useContext(UserContext)

  return useCallback(
    async (
      addressId: string,
      addressFormValues: AddressFormValues,
      token: string
    ) => {
      const { isDefault, ...input } = addressFormValues

      try {
        if (!shopifyStorefrontGraphQlClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        const address = await updateShopifyCustomerAddress(
          locale,
          shopifyStorefrontGraphQlClient,
          token,
          addressId,
          input
        )
        const addressCountryCode = address?.countryCodeV2

        if (addressCountryCode) {
          const addressGlobalId = parseShopifyGlobalId(addressId)
          const addresses = user?.addresses ? [...user.addresses] : []
          const currentDefaultAddress = addresses.find(
            (userAddress) => userAddress.isDefault
          )
          const newDefaultAddress = getNewDefaultAddress(
            addresses,
            isDefault,
            addressGlobalId
          )

          if (
            newDefaultAddress &&
            newDefaultAddress.globalId !== currentDefaultAddress?.globalId
          ) {
            await setShopifyCustomerDefaultAddress(
              locale,
              shopifyStorefrontGraphQlClient,
              token,
              newDefaultAddress.id
            )
          }

          // Mutate user with the new address list
          const newAddressFormValues = getAddressFormValues(address)
          const newAddresses = addresses.map((userAddress) => {
            if (userAddress.globalId === addressGlobalId) {
              const newUserAddress: UserAddress = {
                id: addressId,
                globalId: addressGlobalId,
                formatted: address.formatted,
                isDefault: userAddress.globalId === newDefaultAddress?.globalId,
                countryCode: addressCountryCode,
                values: newAddressFormValues,
              }

              return newUserAddress
            }

            const currentUserAddress: UserAddress = {
              ...userAddress,
              isDefault: userAddress.globalId === newDefaultAddress?.globalId,
            }

            return currentUserAddress
          })
          mutateUser(
            {
              ...user,
              isLoggedIn: !!user?.isLoggedIn,
              addresses: newAddresses,
            },
            {
              revalidate: false,
            }
          )
        }

        return parseResultsOk
      } catch (error) {
        console.log(error)

        return error instanceof ParseError
          ? error.parseResults
          : parseResultsUnknownError
      }
    },
    [locale, mutateUser, shopifyStorefrontGraphQlClient, user]
  )
}

/**
 * Returns a method that deletes a user address.
 */
export const useDeleteAddress = () => {
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)
  const { user, mutateUser } = useContext(UserContext)

  return useCallback(
    async (addressId: string, token: string) => {
      try {
        if (!shopifyStorefrontGraphQlClient) {
          throw new Error('Shopify Storefront API client missing')
        }

        await deleteShopifyCustomerAddress(
          locale,
          shopifyStorefrontGraphQlClient,
          token,
          addressId
        )

        // Mutate user with the new address list
        const addressGlobalId = parseShopifyGlobalId(addressId)
        const addresses = user?.addresses ? [...user.addresses] : []
        const newAddresses: UserAddress[] = [
          ...addresses.filter(
            (address) => address.globalId !== addressGlobalId
          ),
        ]
        mutateUser(
          {
            ...user,
            isLoggedIn: !!user?.isLoggedIn,
            addresses: newAddresses,
          },
          {
            revalidate: false,
          }
        )

        return parseResultsOk
      } catch (error) {
        console.log(error)

        return error instanceof ParseError
          ? error.parseResults
          : parseResultsUnknownError
      }
    },
    [locale, mutateUser, shopifyStorefrontGraphQlClient, user]
  )
}
