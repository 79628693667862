import cx from 'classnames'
import 'intl-tel-input/styles'
import dynamic from 'next/dynamic'
import {
  useCallback,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'

import { defaultCountryCodes } from '@lib/country'
import { LanguageContext } from '@lib/language-context'

const IntlTelInput = dynamic(() => import('intl-tel-input/react'))

interface TelInputFieldProps {
  name: string
  errorMessage?: string
  label?: string
  isRequired?: boolean
  borderBottom?: boolean
  onChange: (value: string) => void
  placeholder?: string
  initialCountryCode?: string
  defaultValue?: string
  enableSearch?: boolean
  className?: string
  inputClassName?: string
}

const TelInputField = ({
  name,
  placeholder,
  label,
  isRequired,
  errorMessage,
  borderBottom,
  onChange,
  initialCountryCode,
  defaultValue,
  enableSearch = false,
  className,
  inputClassName,
}: TelInputFieldProps) => {
  const { locale } = useContext(LanguageContext)

  const [isMounted, setIsMounted] = useState(false)

  const lastValue = useRef<string>(defaultValue ?? '')

  const id = useId()

  useEffect(() => {
    const mountTimeout = setTimeout(() => {
      setIsMounted(true)
    }, 100)

    return () => {
      clearTimeout(mountTimeout)
      setIsMounted(false)
    }
  }, [])

  const handleChange = useCallback(
    (value: string) => {
      if (lastValue.current === value) {
        return
      }

      lastValue.current = value

      onChange(value)
    },
    [onChange]
  )

  const defaultCountryCode = defaultCountryCodes[locale]

  return (
    <div className={cx('grid', className)}>
      <div className={cx('flex flex-col relative text-left text-sm')}>
        {!!label && (
          <label htmlFor={id} className="font-medium mb-2">
            {label}
            {!!isRequired && <span className="ml-0.5 text-red">*</span>}
          </label>
        )}

        <div
          className={cx('flex justify-between', {
            'border-error': errorMessage,
            'border-input-border': !errorMessage,
            'mb-[1px] border-b': borderBottom,
            'border rounded': !borderBottom,
          })}
        >
          {!isMounted && <div className="w-full h-[42px] bg-input-bg"></div>}
          {isMounted && (
            <IntlTelInput
              initialValue={defaultValue}
              initOptions={{
                autoPlaceholder: 'off',
                initialCountry: initialCountryCode ?? defaultCountryCode,
                countryOrder: [defaultCountryCode],
                countrySearch: enableSearch,
                loadUtilsOnInit:
                  'https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js',
                containerClass: 'intl-tel-input w-full',
              }}
              inputProps={{
                id,
                name,
                placeholder,
                className: cx(
                  'relative appearance-none w-full h-full py-3 px-4',
                  'leading-none',
                  'bg-input-bg text-input-text',
                  inputClassName
                ),
              }}
              onChangeNumber={handleChange}
            />
          )}
        </div>
        {errorMessage && (
          <span role="alert" className="mt-2 font-medium text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default TelInputField
