import {
  createCustomerAddressMutation,
  deleteCustomerAddressMutation,
  updateCustomerAddressMutation,
  updateCustomerDefaultAddressMutation,
} from '@data/shopify/storefront/mutations/customers'
import {
  type CreateCustomerAddressMutation,
  type CreateCustomerAddressMutationVariables,
  type DeleteCustomerAddressMutation,
  type DeleteCustomerAddressMutationVariables,
  type MailingAddressFragmentFragment,
  type MailingAddressInput,
  type UpdateCustomerAddressMutation,
  type UpdateCustomerAddressMutationVariables,
  type UpdateCustomerDefaultAddressMutation,
  type UpdateCustomerDefaultAddressMutationVariables,
} from '@data/shopify/storefront/types'
import { type Locale } from '@lib/language'
import { ParseError } from '@lib/request'
import { type ShopifyClient, mutateShopifyStorefront } from './client'
import { parseUserErrors } from './helpers'

/**
 * Creates a new customer address in Shopify.
 */
export const createShopifyCustomerAddress = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  token: string,
  input: MailingAddressInput
) => {
  const createCustomerAddressResult = await mutateShopifyStorefront<
    CreateCustomerAddressMutation,
    CreateCustomerAddressMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, createCustomerAddressMutation, {
    token,
    address: input,
  })

  const createCustomerAddressParseResults = parseUserErrors(
    createCustomerAddressResult.data?.customerAddressCreate?.customerUserErrors
  )

  if (createCustomerAddressParseResults.errorCount > 0) {
    throw new ParseError(createCustomerAddressParseResults)
  }

  const address =
    createCustomerAddressResult.data?.customerAddressCreate?.customerAddress ??
    undefined

  return address as MailingAddressFragmentFragment | undefined
}

/**
 * Updates a customer address in Shopify.
 */
export const updateShopifyCustomerAddress = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  token: string,
  addressId: string,
  input: MailingAddressInput
) => {
  const updateCustomerAddressResult = await mutateShopifyStorefront<
    UpdateCustomerAddressMutation,
    UpdateCustomerAddressMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, updateCustomerAddressMutation, {
    token,
    addressId,
    address: input,
  })

  const updateCustomerAddressParseResults = parseUserErrors(
    updateCustomerAddressResult.data?.customerAddressUpdate?.customerUserErrors
  )

  if (updateCustomerAddressParseResults.errorCount > 0) {
    throw new ParseError(updateCustomerAddressParseResults)
  }

  const address =
    updateCustomerAddressResult.data?.customerAddressUpdate?.customerAddress ??
    undefined

  return address as MailingAddressFragmentFragment | undefined
}

/**
 * Sets customer's default address in Shopify.
 */
export const setShopifyCustomerDefaultAddress = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  token: string,
  addressId: string
) => {
  const updateCustomerDefaultAddressResult = await mutateShopifyStorefront<
    UpdateCustomerDefaultAddressMutation,
    UpdateCustomerDefaultAddressMutationVariables
  >(
    locale,
    shopifyStorefrontGraphQlClient,
    updateCustomerDefaultAddressMutation,
    {
      token,
      addressId,
    }
  )

  const updateCustomerDefaultAddressParseResults = parseUserErrors(
    updateCustomerDefaultAddressResult.data?.customerDefaultAddressUpdate
      ?.customerUserErrors
  )

  if (updateCustomerDefaultAddressParseResults.errorCount > 0) {
    throw new ParseError(updateCustomerDefaultAddressParseResults)
  }
}

/**
 * Deletes a customer address from Shopify.
 */
export const deleteShopifyCustomerAddress = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  token: string,
  addressId: string
) => {
  const deleteCustomerAddressResult = await mutateShopifyStorefront<
    DeleteCustomerAddressMutation,
    DeleteCustomerAddressMutationVariables
  >(locale, shopifyStorefrontGraphQlClient, deleteCustomerAddressMutation, {
    token,
    addressId,
  })

  const deleteCustomerAddressParseResults = parseUserErrors(
    deleteCustomerAddressResult.data?.customerAddressDelete?.customerUserErrors
  )

  if (deleteCustomerAddressParseResults.errorCount > 0) {
    throw new ParseError(deleteCustomerAddressParseResults)
  }
}
