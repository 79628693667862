import { type ReactNode, createContext } from 'react'

interface FormValueContextProps {
  formValues?: Record<string, unknown>
}

interface FormValueProviderProps {
  formValues: Record<string, unknown>
  children: ReactNode
}

export const FormValueContext = createContext<FormValueContextProps>({})

export const FormValueProvider = ({
  formValues,
  children,
}: FormValueProviderProps) => {
  return (
    <FormValueContext.Provider
      value={{
        formValues,
      }}
    >
      {children}
    </FormValueContext.Provider>
  )
}
