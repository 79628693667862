import cx from 'classnames'
import { useContext } from 'react'

import { type SanityAccountAddressDetailsStrings } from '@data/sanity/queries/types/blocks'
import { type AddressFormValues } from '@lib/address'
import { useDeleteAddress } from '@lib/shopify/address'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'
import { type UserAddress } from '@lib/user/types'

import Button from '@components/buttons/button'

interface AccountAddressItemProps {
  address: UserAddress
  accountAddressDetailsStrings: SanityAccountAddressDetailsStrings
  disabled?: boolean
  showEditForm: (id: string, values: AddressFormValues) => void
}

const AccountAddressItem = ({
  address,
  accountAddressDetailsStrings,
  disabled = false,
  showEditForm,
}: AccountAddressItemProps) => {
  const strings = useContext(StringsContext)
  const { user } = useContext(UserContext)

  const deleteAddress = useDeleteAddress()

  const token = user?.token

  if (!token) {
    return null
  }

  return (
    <div className="flex flex-col">
      {!!address.isDefault && (
        <h4
          className={cx('mb-4', {
            'opacity-50': disabled,
          })}
        >
          {accountAddressDetailsStrings.accountDefaultAddressLabel}
        </h4>
      )}

      <div
        className={cx('flex-grow', {
          'opacity-50': disabled,
        })}
      >
        {address.formatted.map((addressLine) => (
          <p key={addressLine}>{addressLine}</p>
        ))}
      </div>

      <div className="flex gap-5 mt-8">
        <Button
          variant="outlined"
          color="gray"
          size="small"
          onClick={() => showEditForm(address.id, address.values)}
          disabled={disabled}
          className="min-w-24"
        >
          {strings.buttonEdit}
        </Button>

        <button
          onClick={() => deleteAddress(address.id, token)}
          disabled={disabled}
          className="text-sm font-medium hover:opacity-60"
        >
          {strings.buttonDelete}
        </button>
      </div>
    </div>
  )
}

export default AccountAddressItem
