import { type SanityAccountDetailsStrings } from '@data/sanity/queries/types/blocks'
import { type SanityCompany } from '@data/sanity/queries/types/customer'

interface AccountCompanyDetailsProps {
  accountDetailsStrings: SanityAccountDetailsStrings
  company?: SanityCompany
}

const AccountCompanyDetails = ({
  accountDetailsStrings,
  company,
}: AccountCompanyDetailsProps) => {
  if (!company) {
    return null
  }

  return (
    <div className="space-y-4">
      <h4>{accountDetailsStrings.accountOrganizationDetailsHeading}</h4>

      <p>
        {company.name}
        <br />

        {[accountDetailsStrings.accountOrganizationVat, company.vatNumber]
          .filter(Boolean)
          .join(' ')}
        <br />

        {company.email}
        <br />

        {[
          accountDetailsStrings.accountOrganizationContactPerson,
          company.contactPerson,
        ]
          .filter(Boolean)
          .join(': ')}
        <br />

        {company.country}
      </p>
    </div>
  )
}

export default AccountCompanyDetails
