import {
  type BaseSyntheticEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityAddressFormStrings } from '@data/sanity/queries/types/blocks'
import { type CountryCode } from '@data/shopify/admin/types'
import { type AddressFormValues } from '@lib/address'
import { countryNames, englishCountryNames } from '@lib/country'
import { type DropdownOption, compareStrings } from '@lib/helpers'
import { LanguageContext } from '@lib/language-context'
import { type ErrorMessages, type ParseResults } from '@lib/request'
import { useCreateAddress, useUpdateAddress } from '@lib/shopify/address'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'

import Alert from '@components/alert'
import Button from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import ComplexPortableText from '@components/complex-portable-text'
import InputDropdown from '@components/input-dropdown/with-form-register'
import InputField from '@components/input-field/with-form-register'

export type AddressFormMode = 'create' | 'edit'

interface AddressFormProps {
  mode: AddressFormMode
  hide: () => void
  addressId?: string
  defaultValues?: AddressFormValues
  addressFormStrings: SanityAddressFormStrings
  className?: string
}

const AddressForm = ({
  addressFormStrings,
  mode,
  hide,
  addressId,
  defaultValues,
  className,
}: AddressFormProps) => {
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const { user } = useContext(UserContext)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<AddressFormValues>()
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const createAddress = useCreateAddress()
  const updateAddress = useUpdateAddress()

  const countryOptions = useMemo<DropdownOption[]>(
    () =>
      Object.entries(countryNames[locale])
        .map(([code, title]) => ({
          value: englishCountryNames[code as CountryCode],
          title,
        }))
        .sort((country1, country2) =>
          compareStrings(country1.title, country2.title)
        ),
    [locale]
  )

  const firstNameRegister = register('firstName', {
    required: addressFormStrings.accountFirstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: addressFormStrings.accountLastNameMissing,
  })
  const companyRegister = register('company', {
    required: addressFormStrings.accountCompanyMissing,
  })
  const address1Register = register('address1', {
    required: addressFormStrings.accountAddressLine1Missing,
  })
  const address2Register = register('address2')
  const cityRegister = register('city', {
    required: addressFormStrings.accountCityMissing,
  })
  const countryRegister = register('country', {
    required: addressFormStrings.accountCountryMissing,
  })
  const zipRegister = register('zip', {
    required: addressFormStrings.accountZipMissing,
  })
  const phoneRegister = register('phone', {
    required: addressFormStrings.accountPhoneMissing,
  })
  const isDefaultRegister = register('isDefault')

  const onSubmit = useCallback(
    async (values: AddressFormValues, event?: BaseSyntheticEvent) => {
      event?.preventDefault()

      const token = user?.token

      if (!token) {
        setIsError(true)
        return
      }

      setIsLoading(true)
      setIsError(false)

      let parseResults: ParseResults | undefined

      switch (mode) {
        case 'create': {
          parseResults = await createAddress(values, token)
          break
        }

        case 'edit': {
          if (addressId) {
            parseResults = await updateAddress(addressId, values, token)
          }
          break
        }
      }

      if (typeof parseResults === 'undefined') {
        return
      }

      const { errorCount, fieldErrors, status } = parseResults
      setErrorMessages(fieldErrors)
      setIsLoading(false)

      if (status !== 'ok') {
        setIsError(true)
      }

      if (status !== 'ok' || errorCount > 0) {
        return
      }

      reset()
      hide()
    },
    [addressId, createAddress, hide, mode, reset, updateAddress, user]
  )

  const isDisabled =
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.company ||
    !!errors.address1 ||
    !!errors.city ||
    !!errors.country ||
    !!errors.zip ||
    !!errors.phone

  return (
    <div className={className}>
      <h4>
        {mode === 'create' && (
          <>{addressFormStrings.accountAddAddressHeading}</>
        )}
        {mode === 'edit' && <>{addressFormStrings.accountEditAddressHeading}</>}
      </h4>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
        <div className="grid grid-cols-6 gap-x-8 gap-y-5">
          <div className="col-span-6 sm:col-span-3">
            <InputField
              type="text"
              formRegister={firstNameRegister}
              defaultValue={defaultValues?.firstName}
              errorMessage={
                errorMessages?.firstName ?? errors.firstName?.message
              }
              label={addressFormStrings.accountFirstName}
              placeholder={addressFormStrings.accountFirstNamePlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              type="text"
              formRegister={lastNameRegister}
              defaultValue={defaultValues?.lastName}
              errorMessage={errorMessages?.lastName ?? errors.lastName?.message}
              label={addressFormStrings.accountLastName}
              placeholder={addressFormStrings.accountLastNamePlaceholder}
            />
          </div>

          <div className="col-span-6">
            <InputField
              type="text"
              formRegister={companyRegister}
              defaultValue={defaultValues?.company}
              errorMessage={errorMessages?.company ?? errors.company?.message}
              label={addressFormStrings.accountCompany}
              placeholder={addressFormStrings.accountCompanyPlaceholder}
            />
          </div>

          <div className="col-span-6">
            <InputField
              type="text"
              formRegister={address1Register}
              defaultValue={defaultValues?.address1}
              errorMessage={errorMessages?.address1 ?? errors.address1?.message}
              label={addressFormStrings.accountAddressLine1}
              placeholder={addressFormStrings.accountAddressLine1Placeholder}
            />
          </div>

          <div className="col-span-6">
            <InputField
              type="text"
              formRegister={address2Register}
              defaultValue={defaultValues?.address2}
              errorMessage={errorMessages?.address2 ?? errors.address2?.message}
              label={addressFormStrings.accountAddressLine2}
              placeholder={addressFormStrings.accountAddressLine2Placeholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              type="text"
              formRegister={cityRegister}
              defaultValue={defaultValues?.city}
              errorMessage={errorMessages?.city ?? errors.city?.message}
              label={addressFormStrings.accountCity}
              placeholder={addressFormStrings.accountCityPlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputDropdown
              formRegister={countryRegister}
              defaultValue={defaultValues?.country}
              options={countryOptions}
              errorMessage={errorMessages?.country ?? errors.country?.message}
              label={addressFormStrings.accountCountry}
              placeholder={addressFormStrings.accountCountryPlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              type="text"
              formRegister={zipRegister}
              defaultValue={defaultValues?.zip}
              errorMessage={errorMessages?.zip ?? errors.zip?.message}
              label={addressFormStrings.accountZip}
              placeholder={addressFormStrings.accountZipPlaceholder}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              type="text"
              formRegister={phoneRegister}
              defaultValue={defaultValues?.phone}
              errorMessage={errorMessages?.phone ?? errors.phone?.message}
              label={addressFormStrings.accountPhone}
              placeholder={addressFormStrings.accountPhonePlaceholder}
            />
          </div>

          <div className="col-span-6">
            <Checkbox
              formRegister={isDefaultRegister}
              defaultChecked={defaultValues?.isDefault}
              className="text-sm font-medium"
            >
              {addressFormStrings.accountSetAsDefault}
            </Checkbox>
          </div>

          {isError && (
            <div key="error" className="col-span-6 my-4">
              <Alert error>
                <ComplexPortableText
                  className="rc rc-alert rc-error"
                  content={addressFormStrings.addressErrorMessage}
                />
              </Alert>
            </div>
          )}

          <div className="col-span-6 mt-4">
            <Button
              type="submit"
              variant="filled"
              disabled={isLoading || isDisabled}
              className="min-w-[180px] xs:mr-7 mb-4 w-full xs:w-auto"
            >
              {isLoading && <>{strings.buttonSubmitting}</>}
              {!isLoading && mode === 'create' && (
                <>{addressFormStrings.accountAddAddress}</>
              )}
              {!isLoading && mode === 'edit' && (
                <>{addressFormStrings.accountEditAddress}</>
              )}
            </Button>

            <Button
              variant="outlined"
              color="gray"
              onClick={(event) => {
                event.preventDefault()
                hide()
              }}
              className="min-w-[180px] w-full xs:w-auto"
            >
              {strings.buttonCancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddressForm
