import axios, { type AxiosResponse } from 'axios'

import { type ContactFormValues } from './form'
import { type Locale } from './language'

export interface KlaviyoBookADemoPayload {
  listId: string
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
  company?: string
}

export interface KlaviyoBookADemoResponse {
  error?: string
}

export interface KlaviyoNewsletterJoinPayload {
  listId: string
  name?: string
  email: string
}

export interface KlaviyoNewsletterJoinResponse {
  error?: string
}

export interface KlaviyoWaitlistJoinPayload {
  variantId: number
  email: string
  name?: string
}

export interface KlaviyoWaitlistJoinResponse {
  error?: string
}

export interface CustomFieldNameMap {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  company?: string
}

export interface ClickUpContactPayload {
  listId: string
  values: ContactFormValues
  customFieldNameMap?: CustomFieldNameMap
}

export interface ClickUpContactResponse {
  error?: string
}

/**
 * Gets API endpoint URL.
 */
const getApiUrl = (service: string, action: string) =>
  `/api/${service.toLowerCase()}/${action}`

/**
 * Gets API headers.
 */
const getApiHeaders = (locale: Locale): Record<string, string> => ({
  'Content-Type': 'application/json',
  'X-Locale': locale,
})

/**
 * Calls an API endpoint.
 */
const callApi = async <T>(
  url: string,
  payload: object,
  headers: Record<string, string>
) =>
  await axios.post<T, AxiosResponse<T>, string>(url, JSON.stringify(payload), {
    headers,
  })

/**
 * Calls newsletter signup API page.
 */
export const addEmailToNewsletterList = async (
  locale: Locale,
  service: string,
  listId: string,
  email: string,
  name?: string
) => {
  const url = getApiUrl(service, 'newsletter-join')
  const headers = getApiHeaders(locale)

  if (service === 'klaviyo') {
    const klaviyoNewsletterJoinPayload: KlaviyoNewsletterJoinPayload = {
      listId,
      email,
      name,
    }
    const response = await callApi<KlaviyoNewsletterJoinResponse>(
      url,
      klaviyoNewsletterJoinPayload,
      headers
    )
    return response
  }
}

/**
 * Calls waitlist signup API page.
 */
export const addEmailToWaitlist = async (
  locale: Locale,
  service: string,
  variantId: number,
  email: string,
  name?: string
) => {
  const url = getApiUrl(service, 'waitlist-join')
  const headers = getApiHeaders(locale)

  if (service === 'klaviyo') {
    const klaviyoWaitlistJoinPayload: KlaviyoWaitlistJoinPayload = {
      variantId,
      email,
      name,
    }
    const response = await callApi<KlaviyoWaitlistJoinResponse>(
      url,
      klaviyoWaitlistJoinPayload,
      headers
    )
    return response
  }
}

/**
 * Calls demo booking API page.
 */
export const bookADemo = async (
  locale: Locale,
  service: string,
  listId: string,
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  company?: string
) => {
  const url = getApiUrl(service, 'book-a-demo')
  const headers = getApiHeaders(locale)

  if (service === 'klaviyo') {
    const klaviyoBookADemoPayload: KlaviyoBookADemoPayload = {
      listId,
      email,
      phoneNumber,
      firstName,
      lastName,
      company,
    }
    const response = await callApi<KlaviyoBookADemoResponse>(
      url,
      klaviyoBookADemoPayload,
      headers
    )
    return response
  }
}

/**
 * Calls contact API page.
 */
export const contact = async (
  locale: Locale,
  service: string,
  listId: string,
  values: ContactFormValues,
  customFieldNameMap?: CustomFieldNameMap
) => {
  const url = getApiUrl(service, 'contact')
  const headers = getApiHeaders(locale)

  if (service === 'clickup') {
    const clickUpContactPayload: ClickUpContactPayload = {
      listId,
      values,
      customFieldNameMap,
    }
    const response = await callApi<ClickUpContactResponse>(
      url,
      clickUpContactPayload,
      headers
    )
    return response
  }
}
